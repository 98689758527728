import { Outlet } from "react-router-dom"
import {Suspense} from "react"
import React, { useState, useEffect } from 'react';
import { translateTeamName } from '../../helpers/translations';
import { translateTimestamp } from '../../helpers/dateHelpers';


export default function CardNBA(event) {
console.log(event);
    return (
        <>
        <div className="game-card dp01">
            <div className="game-card-row game-card-info">
                <div className="game-card-time">{translateTimestamp(event.data.fields.commence_time.integerValue)}</div>
            </div>
            <div className="game-card-row game-card-top">
                <div className="col col-one">
                    <div>{translateTeamName('nba', event.data.fields.away_team.stringValue)}</div>
                </div>
                <div className="col col-two">
                    17-14
                </div>
            </div>
            <div class="game-card-row game-card-bottom">
                <div className="col col-one">
                    <div>{translateTeamName('nba', event.data.fields.home_team.stringValue)}</div>
                </div>
                <div className="col col-two">
                    11-20
                </div>
            </div>
        </div>
        </>
    )
}
