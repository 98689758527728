import moment from "moment";

export function translateTimestamp(timestamp) {
    timestamp = timestamp*1000;
    var t = new Date( timestamp );

    if (moment(t).isSame(moment(), 'day')) {
        return "Today " + moment(t).format("h:mma");
    }

    return moment(t).format("dddd h:mma");
}