import { Link } from "react-router-dom";
import '../App.css';
//import "../styles/Header.css";
import PathConstants from "../routes/pathConstants";
import { useLocation } from "react-router-dom";

export default function Header() {

    //assigning location variable
    const location = useLocation();
    
    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
          <div className="header-items">
            <Link className={splitLocation[1] === "" ? "active header-item" : "header-item"} to={PathConstants.HOME}>
                <div>Home</div>
            </Link>
            <Link className={splitLocation[1] === "mlb" ? "active header-item" : "header-item"} to={PathConstants.MLB}>
                <div>MLB</div>
            </Link>
          </div>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
      
    </header>
  );
}
