import { Outlet } from "react-router-dom"
import {Suspense} from "react"
import React, { useState, useEffect } from 'react';
//import { translateTeamName } from '../../helpers/translations';
import { translateTimestamp } from '../../helpers/dateHelpers';
import axios from 'axios';

export default function CardNBA(event) {
    const [inning, setInning] = useState('Not started');

    /*useEffect(() => {
        axios.get('https://firestore.googleapis.com/v1/projects/bigdipwatch-1074f/databases/(default)/documents/events')
        .then(response => {
          let eventReturn = JSON.parse(JSON.stringify(response.data.documents));
          eventReturn.sort((a,b) => a.fields.commence_time.integerValue - b.fields.commence_time.integerValue);
          let eventArray = eventReturn.map((event) => <CardMLB key={event.fields.id.stringValue} data={event}></CardMLB>);
          setEvents(eventArray);
          setLoading(false);        
        })
      }, []);*/
console.log(event);
    return (
        <>
        <div className="game-card dp01">
            <div className="game-card-row game-card-info">
                <div className="game-card-time col-one col">{translateTimestamp(event.data.fields.commence_time.integerValue)}</div>
                <div className="game-card-time col-two col">Prediction</div>
                <div className="game-card-time col-three col">Soy Index</div>
                <div className="game-card-time col-four col">Moneyline</div>
                <div className="game-card-time col-four col">{inning}</div>
            </div>
            <div className="game-card-row game-card-top">
                <div className="col col-one">
                    <div>{event.data.fields.away_team.stringValue}</div>
                </div>
                <div className="col col-two">
                    <div>{event.data.fields.away_prediction.stringValue}</div>
                </div>
                <div className="col col-three">
                    <div>{event.data.fields.away_soy.stringValue}</div>
                </div>
                <div className="col col-four">
                    <div>-110</div>
                </div>
            </div>
            <div class="game-card-row game-card-bottom">
                <div className="col col-one">
                    <div>{event.data.fields.home_team.stringValue}</div>
                </div>
                <div className="col col-two">
                    <div>{event.data.fields.home_prediction.stringValue}</div>
                </div>
                <div className="col col-three">
                    <div>{event.data.fields.home_soy.stringValue}</div>
                </div>
                <div className="col col-four">
                    <div>-110</div>
                </div>
            </div>
        </div>
        </>
    )
}
