import React from "react"
import PathConstants from "./pathConstants"

const Home = React.lazy(() => import("../pages/home"))
const MLB = React.lazy(() => import("../pages/mlb"))
//const NBA = React.lazy(() => import("../pages/nba"))

const routes = [
    { path: PathConstants.HOME, element: <Home /> },
    { path: PathConstants.MLB, element: <MLB /> }
]
export default routes
