import { Outlet } from "react-router-dom"
import Loader from "./Loader"
import {Suspense} from "react"
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CardNBA from './eventCards/CardNBA'

export default function LayoutNBA() {
  const [isLoading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  
  useEffect(() => {
      axios.get('https://firestore.googleapis.com/v1/projects/bigdipwatch-1074f/databases/(default)/documents/events')
      .then(response => {
        let eventReturn = JSON.parse(JSON.stringify(response.data.documents));
        eventReturn.sort((a,b) => a.fields.commence_time.integerValue - b.fields.commence_time.integerValue);
        let eventArray = eventReturn.map((event) => <CardNBA key={event.fields.id.stringValue} data={event}></CardNBA>);
        setEvents(eventArray);
        setLoading(false);        
      })
    }, []);

    if (isLoading) {
      return <div>Loading...</div>
    }

    return (
        <>
        <div className="events-list">
            <div className="event-wrapper">{events}</div>
        </div>
        </>
    )
}
