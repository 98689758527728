import { Outlet } from "react-router-dom"
import Loader from "./Loader"
import {Suspense} from "react"
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CardMLB from './eventCards/CardMLB'
import CardMLBMobile from './eventCards/CardMLBMobile'

export default function LayoutNBA() {
  const [isLoading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [eventsMobile, setEventsMobile] = useState([]);
  
  useEffect(() => {
      axios.get('https://firestore.googleapis.com/v1/projects/bigdipwatch-1074f/databases/(default)/documents/events')
      .then(response => {
        let eventReturn = JSON.parse(JSON.stringify(response.data.documents));
        eventReturn.sort((a,b) => a.fields.commence_time.integerValue - b.fields.commence_time.integerValue);
        let eventArray = eventReturn.map((event) => <CardMLB key={event.fields.id.stringValue} data={event}></CardMLB>);
        let eventArrayMobile = eventReturn.map((event) => <CardMLBMobile key={event.fields.id.stringValue} data={event}></CardMLBMobile>);
        setEvents(eventArray);
        setEventsMobile(eventArrayMobile);
        setLoading(false);        
      })
    }, []);

    if (isLoading) {
      return <div>Loading...</div>
    }

    return (
        <>
        <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10 d-none d-md-block">
              <div className="events-list">
                <div className="event-wrapper">{events}</div>
              </div>
            </div>
            <div className="col-sm-10 col-xs-10 d-md-none">
              <div className="events-list">
                <div className="event-wrapper">{eventsMobile}</div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
        
        </>
    )
}
