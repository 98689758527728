import { Outlet } from "react-router-dom"
//import "../styles/Layout.css"
import Header from "./Header"
import LayoutMLB from "./LayoutMLB"
import LayoutNBA from "./LayoutNBA"
//import Footer from "./Footer"
import Loader from "./Loader"
import {Suspense} from "react"
import { useLocation } from "react-router-dom";

export default function Layout() {

    //assigning location variable
    const location = useLocation();
    
    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    let currentLayout = (
        <>
        </>
    );

    if (splitLocation[1] === "mlb") {
        currentLayout = (<LayoutMLB/>);
    } else if (splitLocation[1] === "nba") {
        currentLayout = (<LayoutNBA/>);
    }

    return (
        <>
        <div>
            <Header />
            <main>
                <Suspense fallback={<Loader />}>
                    <Outlet />
                </Suspense>
                <div>{currentLayout}</div>
            </main>
        </div>
        </>
    )
}
