
export function translateTeamName(sport, team) {
    if (sport == 'nba') {
        switch (team) {
            case 'Atlanta Hawks':
                return 'ATL Hawks';
            case 'Boston Celtics':
                return 'BOS Celtics';
            case 'Denver Nuggets':
                return 'DEN Nuggets';
            case 'Los Angeles Clippers':
                return 'LA Clippers';
            case 'Milwaukee Bucks':
                return 'MIL Bucks';
            case 'Oklahoma City Thunder':
                return 'OKC Thunder';
            case 'Phoenix Suns':
                return 'PHO Suns';
            case 'Dallas Mavericks':
                return 'DAL Mavericks';
            case 'Philadelphia 76ers':
                return 'PHI 76ers';
            case 'Minnesota Timberwolves':
                return 'MIN Timberwolves';
            case 'Los Angeles Lakers':
                return 'LA Lakers';
            case 'Miami Heat':
                return 'MIA Heat';
            case 'New York Knicks':
                return 'NY Knicks';
            case 'Golden State Warriors':
                return 'GS Warriors';
            case 'New Orleans Pelicans':
                return 'NO Pelicans';
            case 'Sacramento Kings':
                return 'SAC Kings';
            case 'Indiana Pacers':
                return 'IND Pacers';
            case 'Orlando Magic':
                return 'ORL Magic';
            case 'Chicago Bulls':
                return 'CHI Bulls';
            case 'Houston Rockets':
                return 'HOU Rockets';
            case 'Portland Trail Blazers':
                return 'POR Trail Blazers';
            case 'Charlotte Hornets':
                return 'CHA Hornets';
            case 'Memphis Grizzlies':
                return 'MEM Grizzlies';
            case 'Detroit Pistons':
                return 'DET Pistons';
            case 'Utah Jazz':
                return 'UTAH Jazz';
            case 'San Antonio Spurs':
                return 'SA Spurs';
            case 'Brooklyn Nets':
                return 'BKN Nets';
            case 'Washington Wizards':
                return 'WAS Wizards';
            case 'Toronto Raptors':
                return 'TOR Raptors';
            
        }
    }
    return team;
}